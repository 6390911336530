<template>
    <div class="px-3 py-2" :style="`direction:`+lang.dir">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_store" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.add_store}}</span>
                        </div>
                        <div @click="hide" id="hideAddStore" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-row :style="`margin:auto 5px;direction:${lang.dir}`">
                        <v-col cols="12" md="6">
                            <label>{{ lang.store_name_ar }}</label>
                            <b-form-input v-model="store_name_ar" class="inborder"></b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{ lang.store_name_en }}</label>
                            <b-form-input v-model="store_name_en" class="inborder"></b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{ lang.account_number }}</label>
                            <b-form-input v-model="acount_name" list="classes-list" @change="changeMe()"></b-form-input>
                            <datalist id="classes-list">
                                <option v-for="(item,index) in accs" :key="index">{{item.classid}} - {{ item.namear }}</option>
                            </datalist>
                        </v-col>
                    </v-row>
                    
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-5">
                        <b-button type="button" @click="add()" variant="success" class="ma-2" style="width:100px;">{{lang.save}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    components: {},
    data(){
        return {
            id: 0,
            store_name_ar: '',
            store_name_en: '',
            classid: '',
            accs: [],
            acount_name: '',

        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
        },
        accounts: function() {
            let t = [];
            for(let i=0;i<this.accs.length;i++){
                t.push({
                    text: this.classid + " - " + this.accs[i][`name${this.lang.langname}`],
                    value: this.classid
                })
            }
            return t;
        }
    },
    created(){
        this.getAccounts();
    },
    methods:{
        changeMe(){
            const myclasses = this.acount_name.split(" - ");
            this.classid = myclasses[0]
        },
        getAccountName(){
            for(let i=0;i<this.accs.length;i++){
                if(this.accs[i].classid == this.classid){
                    this.acount_name = this.classid + " - " + this.accs[i][`name${this.lang.langname}`]
                }
            }
        },
        printPdf(){
            window.open('./api/print.php?calendar='+this.calid);
        },
        openWhatsAPP(){
            window.open('https://wa.me/'+this.calendarData.rows[0]['mobile']);
        },
        resetAllValues(){
            this.id = 0;
            this.store_name_ar = '';
            this.store_name_en = '';
            this.classid = '';
        },
        
        async add(){
            const post = new FormData();
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('type',"addStore");
            post.append('auth',cook)
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',this.id)
            post.append('data[store_name_ar]',this.store_name_ar)
            post.append('data[store_name_en]',this.store_name_en)
            post.append('data[classid]',this.classid)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post)
            // console.log(response.data.results.data)
            if(response && response.data && response.data.results && response.data.results.data){
                const res = response.data.results.data;
                this.calendarData = res;
                this.$parent.getStores();
                document.getElementById('hideAddStore').click();
            }
        },
        getAccounts(){
            const post = new FormData();
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase)
            post.append("type","getPostedCOA");
            post.append("auth",cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((resp) => {
                this.accs = resp.data.results.data;
            })
        }
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>